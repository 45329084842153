'use client';
import { ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';

import { initTranslations, TranslationsProvider } from '@/components/TranslationsProvider';
import { GlobalStyles, lightTheme } from '@/styles/theme';

export interface ClientProvidersProps {
  locale: string;
  children: ReactNode;
}

export const ClientProviders = async ({ children, locale }: ClientProvidersProps) => {
  const queryClient = new QueryClient();
  const i18nNamespaces = ['common', 'filter'];
  const { resources } = await initTranslations(locale, i18nNamespaces);

  return (
    <TranslationsProvider namespaces={i18nNamespaces} locale={locale} resources={resources}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          {children}
        </ThemeProvider>
      </QueryClientProvider>
    </TranslationsProvider>
  );
};
