import { createGlobalStyle, DefaultTheme } from 'styled-components';

const secondaryColor = '140deg 57%';

export const lightTheme: DefaultTheme = {
  colors: {
    primary: '#6AC788',
    secondary: '#A1E5B7',
    error: '#D26363',
    secondaryHover: `hsl(${secondaryColor} 50%)`,
    background: '#FAFAFA',
    text: '#123B2B',
    actionHover: 'rgba(106, 199, 136, 0.20)',
    borderColor: 'rgba(18, 59, 43, 0.20)',
    shadow: 'rgba(0, 0, 0, 0.12)',
    border: '#123B2B33',
    backgroundReviewsCard: '#F0F0F0',
    whiteText: '#FFFFFF',
    tooltipBackground: '#F0F9F3',
    buttonBackground: 'rgba(255, 255, 255, 0.80)',
    popUpCloseButton: 'rgba(60, 60, 67, 0.6)',
  },
  typography1: {
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '2rem',
  },
  typography3: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: '1rem',
  },
  typography6: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: '1.125rem',
  },
  typography4: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: '0.875rem',
  },
  typography2: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: '1.5rem',
  },
  typography5: {
    fontWeight: '600',
    lineHeight: '14px',
    fontSize: '0.75rem',
  },

  dimensions: {
    headerHeight: 100,
    mobileHeaderHeight: 64,
    filterHeight: 50,
  },
  spacing: {
    1: '8px',
    2: '16px',
    3: '24px',
    4: '32px',
    5: '40px',
  },
};

export const GlobalStyles = createGlobalStyle`
html,
body {
  width: 100vw;
  height: 100vh;
  font-family : var(--font-poppins);
  color: #123B2B;
}
*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
`;
